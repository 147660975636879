import axios from "axios";

const MenuService = {
  async getList(query) {
    const response = await axios.get(
      `${process.env.BASE_API_URL}menus?sort[0]=id&populate=*&pagination[page]=1&pagination[pageSize]=50`
    );
    return response.data;
  },
};

export default MenuService;
