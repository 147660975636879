import React, { createContext, useState, useContext, useEffect } from "react";
import MenuService from "@services/menu";

export interface DataContextType {
  menus?: {
    data: any;
    meta: any;
  };
}

export const dataContextDefaultValue: DataContextType = {
  menus: {
    data: null,
    meta: null,
  },
};

export const DataStateContext = createContext<DataContextType>(
  dataContextDefaultValue
);

export const DataProvider = ({ children }) => {
  const [menus, setMenus] = useState(null);

  useEffect(() => {
    async function loadDatas() {
      await getMenus();
    }
    loadDatas();
  }, []);

  const getMenus = async () => {
    try {
      const result = await MenuService.getList("");

      if (result) {
        await setMenus(result);
      } else {
        return null;
      }
    } catch (err) {
      console.log("aldaa garlaa");
    }
  };

  return (
    <DataStateContext.Provider
      value={{
        menus,
      }}
    >
      {children}
    </DataStateContext.Provider>
  );
};

export const useDataState = () => useContext(DataStateContext);
