import { FC, useEffect, useState } from "react";
import { appWithTranslation } from "next-i18next";
import type { AppProps } from "next/app";

import Router from "next/router";
import NProgress from "nprogress"; //nprogress module
import "nprogress/nprogress.css"; //styles of nprogress

import "@styles/ckeditor.scss";
import "@styles/globals.css";

import { DataProvider } from "@context/menu";

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

const MyApp: FC<AppProps> = ({ Component, router, pageProps }: AppProps) => {
  // const [pageLoading, setPageLoading] = useState(false);

  // useEffect(() => {
  //   const handleStart = () => {
  //     setPageLoading(true);
  //   };
  //   const handleComplete = () => {
  //     setPageLoading(false);
  //   };

  //   router.events.on("routeChangeStart", handleStart);
  //   router.events.on("routeChangeComplete", handleComplete);
  //   router.events.on("routeChangeError", handleComplete);
  // }, [router]);

  return (
    <DataProvider>
      <Component {...pageProps} />
    </DataProvider>
  );
};

export default appWithTranslation(MyApp);
